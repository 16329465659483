@import 'src/assets/css/_variables.scss';

.detail-content-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #515c6f;
  margin-bottom: 5px;
  word-break: break-word;
}

.detail-content-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #515c6f;
}

.detail-content-description {
  height: max-content;
  margin-bottom: 100px;
  img {
    max-width: 100%;
  }
  * {
    max-width: 100%;
    white-space: initial;
    word-break: break-word;
  }
  ul,
  ol {
    padding-left: 15px;
  }
  //custom class in ck editor
  .image.image_resized {
    // margin-left: auto;
    // margin-right: auto;
    &.image-style-side {
      margin-left: unset;
      margin-right: unset;
      float: right;
    }
  }
  ul {
    list-style: initial;
  }
  ol {
    list-style: auto;
  }
  table {
    border: 1px double #b3b3b3;
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    width: 100%;
    td,
    th {
      border: 1px solid #bfbfbf;
      min-width: 2em;
    }
  }
  .ck-table-resized,
  figure.table {
    margin-left: auto;
    margin-right: auto;
  }
  a {
    color: #1890ff;
    text-decoration: underline;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
}

.detail-content-back {
  box-shadow: 0 5px 10px 0 rgba(248, 60, 106, 0.396);
  width: 210px !important;
  background: #1d1d1d;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  border-radius: 25px;
  position: relative;
  border: none;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    background: #1d1d1d;
    color: #fff;
    outline: none;
    box-shadow: 0 5px 10px 0 rgba(248, 60, 106, 0.396);
    border: none;
  }
}

.detail-content-divider {
  display: block;
  height: 1px;
  width: 100%;
  background: #707070;
  margin: 20px 0;
}

.detail-back-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.button-preview {
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  margin: auto;
  border-radius: 8px;
  border: 1px solid #000;
  background: transparent;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-family: 'Noto Sans JP';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  &:hover,
  &:focus,
  &:active {
    background: #bdbdbd;
    color: rgba(0, 0, 0, 0.85);
    outline: none;
    box-shadow: none;
    border: 1px solid #000;
  }
}

.detail-notification {
  height: auto;
  min-height: calc(100vh - $height-header - 105px);
  display: flex;
  flex-direction: column;
}
