.wrapRenderStep {
  padding: 0 20px;
}

.step1 {
  position: relative;

  .holeType {
    margin-bottom: 20px;

    .title {
      color: #f5f6f8;
      font-size: 13px;
      font-weight: 700;
      background-color: #5277f0;
      padding: 0 0 0 10px;
      margin-bottom: 13px;
    }

    .holes {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 8px;
      align-items: center;
      justify-content: flex-start;
      background: #fff;
      gap: 8px;

      .holeItem {
        position: relative;
        width: 70px;
        height: 70px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        text-align: center;

        img {
          object-fit: contain;
          position: relative !important;
          margin-bottom: 2px;
          height: 45px;
        }

        p {
          margin: 0;
          font-size: 12px;
        }
      }

      .holeItemBorder {
        border-radius: 8px;
        border: 1px dashed #5277f0;
      }
    }
  }

  .buttonContinue {
    position: fixed;
    width: 100%;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #f5f6f8;
  }
}
