.wrapRenderStep {
  padding: 0 20px;
}

.step1 {
  .wrapImage {
    background-color: white;
    border: 1px solid black;
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: relative !important;
      object-fit: contain;
    }
  }
}
