//color
$main: #1c48e7;
$black: #000;
$white: #fff;
$primary: #2e2e2e;
$secondary: #c0c0c0;
$blue: #5277f0;
$danger: #f42929;
$azure: #ffffff;
$bgAccepted: #fee9de;
$accepted: #ea9617;
$bgProcessing: #d1defd;
$bgSuccess: #e7fad4;
$success: #27ae60;
$mint: #def5fd;
$rose: #e33636;

// variable
$height-header: 64px;
$height-ratio-notify: 184px;
$width-ratio-notify: 325px;
