.reject-button {
  border: 1px solid #1c48e7;
  border-radius: 8px;
  padding: 8px 12px;
  color: #1c48e7;
  font-weight: 700;
  font-size: 16px;
  width: 128px;
  height: 40px;
}

.accept-button {
  border: 1px solid #1c48e7;
  background: #1c48e7;
  border-radius: 8px;
  padding: 8px 12px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  width: 128px;
  height: 40px;
}

.accept-icon {
  background: rgba(28, 72, 231, 0.1);
  border: 1px solid #1c48e7;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  padding: 0px;
  &:hover,
  &:focus,
  &:active {
    background: rgba(28, 72, 231, 0.1);
  }
  img {
    margin: 0 auto;
    background: transparent;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border: none;
    }
  }
}

.reject-icon {
  background: rgba(244, 41, 41, 0.1);
  border: 1px solid #f42929;
  border-radius: 10px;
  width: 32px;
  height: 32px;
  padding: 0px;
  &:hover,
  &:focus,
  &:active {
    background: rgba(244, 41, 41, 0.1);
    border: 1px solid #f42929;
  }
  img {
    margin: 0 auto;
    background: transparent;
    &:hover,
    &:focus,
    &:active {
      background: transparent;
      border: none;
    }
  }
}

.status-request {
  border-radius: 4px;
  width: 67px;
  height: 33px;
  font-weight: 400;
  font-size: 14px;
  line-height: 33px;
  text-align: center;
}
.status-accept {
  background: #d5edfe;
  color: #2263cb;
}

.status-reject {
  background: #fee9de;
  color: #ea9617;
}

.status-expired {
  background: #c0c0c0;
  color: #272122;
}

.company-request-checkbox .ant-checkbox-inner {
  border: 1.5px solid #2e2e2e;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.reject-button.ant-btn[disabled] {
  border: 1px solid #a3bdfc;
  color: #a3bdfc;
  background: #ffffff;
}

.accept-button.ant-btn[disabled] {
  border: 1px solid #a3bdfc;
  color: #ffffff;
  background: #a3bdfc;
}

.w-detail-content {
  width: 50%;
}

.tab-category {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1c48e7;
  }

  .ant-tabs-ink-bar {
    background: #1c48e7;
  }

  .ant-tabs-nav {
    &::before {
      border-bottom: unset;
    }
  }
}

.modal-category {
  .ant-modal-content {
    border-radius: 8px;

    svg {
      fill: #fff;
    }

    .ant-modal-header {
      background: #1c48e7;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .ant-modal-title {
        color: #ffffff;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .ant-modal-body {
      .form {
        .form_item {
          margin-bottom: 24px;
          .language {
            color: #1c48e7;
          }

          .label_form {
            color: #2e2e2e;
            font-size: 16px;
            letter-spacing: 0.5px;

            span {
              color: #ca3f4b;
              font-weight: 400;
            }
          }

          .input_form {
            border-radius: 8px;
            border: 1px solid #c0c0c0;
            height: 48px;
          }

          .input_form_textarea {
            height: 120px !important;
            resize: none;
          }
        }

        .button_item {
          &:first-child {
            color: #272122;
            background: #eaeaea;
          }

          &:last-child {
            background: #a3bdfc;
          }
        }
      }
    }
  }
}

.company-request-status {
  padding: 6px 12px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
}

.notify-request-status {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  border-radius: 50px;
  text-align: center;
  padding: 4px 14px;
}

.order-product-thumbnail {
  display: flex;
  width: 120px;
  height: 120px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  min-width: 120px;
}

.order-product-image {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.order-product-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.order-product-title {
  color: #2e2e2e;
  font-family: 'Noto Sans JP';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-break: break-word;
}

.order-product-size {
  color: #888;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.order-button-download {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  outline: none;
  border: none;
  min-width: 32px;
  &:hover,
  &:focus,
  &:active {
    background: #fff;
    color: #fff;
    outline: none;
    box-shadow: none;
    border: none;
  }
}

.item-detail-thumbnail {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wrap-order-thumbnail {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 8px 22px;
  width: 100%;
  margin: 16px 0 0;
  display: flex;
  flex-direction: column;
}

.order-detail-thumbnail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  li {
    list-style: none;
    &:nth-of-type(1n + 4) {
      display: none;
    }
  }
  &.full-detail-thumbnail {
    li {
      &:nth-of-type(1n + 4) {
        display: flex !important;
      }
    }
  }
}

.order-detail-more {
  color: #525252;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: underline;
  outline: none;
  border: none;
  background: transparent;
  box-shadow: none;
  margin: 16px 0 0;
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    color: #525252;
    outline: none;
    box-shadow: none;
    border: none;
  }
  * {
    text-decoration: underline;
  }
}

.title-order-thumbnail {
  color: #1c48e7;
  font-weight: 700;
  font-size: 16px;
}

.order-detail-note {
  font-family: Noto Sans JP;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 40px 0 0;
  color: #e33636;
  word-break: break-word;
  white-space: pre-line;
}
