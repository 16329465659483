@import 'src/assets/css/_variables.scss';

.login {
  &__form {
    border-radius: 8px;
    background: $white;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    padding: 24px 24px 40px;
    display: flex;
    width: 604px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    &-content {
      width: 100%;
    }
  }
  .form-title {
    color: $primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.25px;
    text-align: center;
  }
  &__form__guide {
    a {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: $main;
    }
  }
  &__bgr {
    height: 100vh;
    object-fit: fill;
  }
}

.login__form__input {
  border-radius: 8px;
  background: white;
  padding: 8px 16px;
  box-shadow: none !important;
  .ant-input-prefix {
    margin-right: 8px;
  }
  .ant-input {
    font-size: 16px;
  }
  .ant-input-password-icon.anticon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.login__form__input .ant-input::placeholder {
  color: #818181;
  color: #818181 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Noto Sans JP;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.login__form__button {
  background: $main;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  min-height: 48px;
  border: none;
}
