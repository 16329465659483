.editor {
  position: relative;
  display: flex;
  justify-content: center;

  .stage {
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid black;
    overflow: hidden;
    position: relative;

    &.stageImage::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(
          farthest-side,
          transparent calc(100% - 2px),
          black calc(100% - 2px) calc(100% - 1px),
          transparent 100%
        )
        fixed /* Fixed to the screen*/ no-repeat; /* Don't repeat*/
      background-size: var(--bg-size);
      background-position: var(--bg-pos);
    }
  }

  .backupStage {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    & > div:first-child {
      opacity: 0.4;
    }
  }

  .eraseBrushCircle {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    z-index: 1;
    border: 1px solid #0000008f;
  }
}

.content {
  padding: 0 0 20px;
  width: 100%;
  margin: 0 auto;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: auto;
  height: calc(100vh - 70px);

  .iconClose {
    position: -webkit-sticky;
    position: sticky;
    float: right;
    top: 15px;
    z-index: 2;
    align-items: center;
    padding: 0 20px;
  }

  .wrapAction {
    padding: 0 20px;

    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 29px;
      color: #515c6f;
      margin-bottom: 4px;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 10px 0;
      background-color: #fff;
    }

    .scale {
      display: flex;
      justify-content: center;
      width: 100%;
      & > span {
        width: 80%;
        input {
          width: 100%;
        }
      }
    }

    .wrapHole {
      .holeType {
        margin-bottom: 20px;

        .titleHole {
          color: #f5f6f8;
          font-size: 13px;
          font-weight: 700;
          background-color: #1d1d1d;
          padding: 0 0 0 10px;
          margin-bottom: 13px;
        }

        .holes {
          display: flex;
          flex-wrap: wrap;
          padding: 10px 8px;
          align-items: center;
          justify-content: flex-start;
          background: #fff;
          gap: 8px;

          .holeItem {
            position: relative;
            width: 70px;
            height: 70px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            img {
              object-fit: contain;
              position: relative !important;
              border-radius: 9px;
            }
          }

          .holeItemBorder {
            border-radius: 8px;
            border: 1px dashed #5277f0;
          }
        }
      }
    }
  }
}
