.menuBottom {
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  padding: 0 40px;

  &.haveSub {
    height: 100px;
  }

  .itemMenuBottom {
    border-radius: 8px;
    flex: 1;
    border: 1px dashed #5277f0;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    min-width: 100px;
    max-width: fit-content;

    .control {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .name,
      .sub {
        color: #818181;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .active {
      .name,
      .sub {
        color: #1890ff;
      }
    }
  }
}
