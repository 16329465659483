.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

/* Modal Content */
.modal-content {
  z-index: 99;
  padding: 40px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fefefe;
  width: 1300px;
  height: calc(100vh - 150px);
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.icon-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
}

.sample-tutorial {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.sample-spreadsheets {
  width: calc(100% - 280px);
  height: 100%;
  overflow: hidden;
  float: left;
}

.options-container {
  float: right;
  width: 280px;
  padding: 12px;
  height: 100%;
  box-sizing: border-box;
  background: #fbfbfb;
  overflow: auto;
}

.sample-options {
  z-index: 1000;
}

.inputContainer {
  width: 100%;
  height: auto;
  border: 1px solid #eee;
  padding: 6px 12px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.input {
  font-size: 14px;
  height: 30px;
  border: 0;
  outline: none;
  background: transparent;
}

.button {
  height: 30px;
  padding: 6px 12px;
  width: 80px;
  margin-top: 6px;
}

.group {
  padding: 12px;
}

.group input {
  padding: 4px 12px;
}

.modal-preview {
  .ant-modal-content {
    border-radius: 0px !important;
  }
  .ant-modal-body {
    padding: 0px;
    display: flex;
    justify-content: center;
    border-radius: 0px;
    .cover-image-template {
      border: none;
    }
  }
}
