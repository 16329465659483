@import 'src/assets/css/_variables.scss';

.header {
  background: $azure;
  padding: 0 20px;
  position: sticky;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  min-height: $height-header;
  &__user-name {
    color: $black;
    font-family: 'Noto Sans JP';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    width: max-content;
  }
  &__logo {
    min-width: 180px;
  }
}

.user-info {
  top: 68px !important;
  min-width: 196px !important;
  box-shadow: 0px -10px 30px 0px rgba(0, 0, 0, 0.05);
  background: $white;
  border-radius: 8px;
  .ant-dropdown-menu {
    border-radius: 8px;
  }
  .ant-dropdown-menu,
  .ant-dropdown-menu-item {
    padding: 0px;
  }
  .ant-dropdown-menu-item:hover {
    background: $azure;
    .user-info-item {
      background: $azure;
      color: #1437c6;
    }
    .menu-icon {
      path {
        stroke: #1437c6;
      }
    }
    .menu-icon-spec {
      path {
        fill: #1437c6;
      }
    }
  }
  &-item {
    display: flex;
    padding: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $primary;
    img {
      margin-right: 10px;
    }
  }
}

.menu-item {
  font-weight: 700;
  font-size: 16px;
  color: $primary;
  padding: 0 20px;
  transition: all 0.3s;
  &:hover {
    color: #1437c6;
  }
}

.menu-item-active {
  color: $main;
}

.ant-dropdown-trigger {
  display: inline-flex;
  cursor: pointer;
}

.header-menu.ant-menu {
  background: transparent;
  min-height: $height-header;
  border-bottom: none !important;
  gap: 8px;
  li {
    height: 100%;
    padding: 16px !important;
    display: flex;
    align-items: center;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $primary;
  }
  &::before {
    display: none;
  }
  // .ant-menu-submenu {
  //   &::after {
  //     border-bottom: 3px solid $main;
  //     width: 100%;
  //     left: 0;
  //   }
  // }
  .ant-menu-submenu-selected,
  .ant-menu-item-selected {
    background: #1d1d1d;
    color: #ffffff;
    font-weight: 700;
    &::after {
      border-bottom: 3px solid $main;
      width: 100%;
      left: 0;
    }
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active {
  color: #000000;
}

.ant-menu-light .ant-menu-item:hover {
  color: #000000 !important;
}

.ant-menu-sub .ant-menu-item:hover {
  color: #ffffff !important;
  background: #1d1d1d !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 3px solid #1d1d1d;
  width: 100%;
  left: 0;
}

.ant-menu-light .ant-menu-item-active {
  color: #ffffff !important;
}

.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: inherit !important;
}

