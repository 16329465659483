.wrapRenderStep {
  padding: 0 20px;
}

.step1 {
  .wrapChooseStamp {
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 20px;
    gap: 30px;

    .img {
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .listStamp {
      overflow: auto;

      .stampType {
        .title {
          color: #f5f6f8;
          font-size: 13px;
          font-weight: 700;
          background-color: #1890ff;
          padding: 0 0 0 10px;
          margin-bottom: 13px;
        }

        .stamps {
          display: flex;
          flex-wrap: wrap;
          padding: 10px 8px;
          align-items: center;
          justify-content: flex-start;
          background: #fff;
          gap: 5px;
          width: 100%;

          .stampItem {
            position: relative;
            width: 120px;
            height: 120px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            img {
              object-fit: contain;
              position: relative !important;
              border-radius: 9px;
              width: 100%;
              height: 100%;
            }
          }

          .stampItemBorder {
            border-radius: 8px;
            border: 1px dashed #5277f0;
          }
        }
      }

      .buttonContinue {
        position: fixed;
        width: 100%;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        background-color: #f5f6f8;
      }
    }
  }
}
