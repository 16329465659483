.wrapRenderStep {
  padding: 0 20px;
  overflow-x: hidden;
}

.step1 {
  textarea {
    border: 1px solid black;
    width: 100%;
    height: 300px;
    padding: 10px;
    margin-bottom: 10px;
  }
}

.step2 {
  .showText {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0;
    border: 1px solid black;
    margin: 0 auto;

    span {
      text-align: center;
      white-space: nowrap;
    }
  }

  .letterSpacing {
    width: 100%;
    color: #515c6f;
    font-size: 14px;
    font-weight: 400;
  }
}

.wrapBtnSelectLangFont {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0 0 10px 0;
}

.btnLangFont {
  border: 1px solid #cfe2ff;
  padding-inline: 16px;
  border-radius: 8px;
  &__selected {
    @extend .btnLangFont;
    background: #cfe2ff;
  }
}
