.wrapRenderStep {
  padding: 0 20px;
}

.step1 {
  position: relative;

  .socketType {
    margin-bottom: 20px;

    .title {
      color: #f5f6f8;
      font-size: 13px;
      font-weight: 700;
      background-color: #1890ff;
      padding: 0 0 0 10px;
      margin-bottom: 13px;
    }

    .sockets {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 8px;
      align-items: center;
      justify-content: flex-start;
      background: #fff;
      gap: 8px;

      .socketItem {
        position: relative;
        width: 100px;
        height: 80px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        text-align: center;

        .wrapImage {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: contain;
            position: relative !important;
            margin-bottom: 2px;
            height: 45px;
          }
        }

        p {
          margin: 0;
          font-size: 10px;
        }
      }

      .socketItemBorder {
        border-radius: 8px;
        border: 1px dashed #5277f0;
      }
    }
  }

  .buttonContinue {
    position: fixed;
    width: 100%;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #f5f6f8;
  }
}
