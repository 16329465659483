.wrapRulerAndEditor {
  position: relative;
}

.rulerVertical {
  position: absolute;
  transform: rotate(-90deg);
}

.editor {
  padding: 0 20px 0;

  .wrapLayoutEditor {
    display: flex;
    justify-content: center;
    .layoutEditor {
      position: relative;
      border: 1px solid black;
      overflow: hidden;
    }
  }
}

.addToCart {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  button {
    background: #1890ff;
    border: unset;
    border-radius: 50px;

    padding: 8px 8px 8px 20px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.navBar {
  padding: 15px 25px 5px;
  width: 100%;
  margin: 0 auto;
  background: #1890ff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;
  gap: 10px;
}

.modalConfirm {
  :global {
    .ant-modal-content {
      filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.11));
      border-radius: 16px;
      background: #fff;
      padding: 15px 15px 10px 15px;
    }
  }

  .content {
    color: #1890ff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 10px;
  }

  .subContent {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #515c6f;
    font-weight: 400;
    line-height: 22px;
    white-space: pre-line;
    text-align: center;
  }

  .footerForm {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 8px;

    .buttonForm {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 100px;
      padding: 19px 16px;
      border-radius: 8px;
      border: unset;
      background: #1890ff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: white;
    }
  }
}

.header {
  position: relative;
}

.iconBookMark {
  position: absolute;
  right: 19px;
  top: 0;
}
